.fur-eigentumer-v1 {
	margin-top: var(--page-margin-top);

	.owner-scroll-bl {
		overflow: hidden;
		margin-bottom: 30px;

		.owners-img {
			height: 430px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&:hover {
			.owners-bl {
				top: -70px;
			}
		}
	}

	.owners-bl {
		position: relative;
		top: 0;
		transition: all 0.2s ease;

		.owners-text {
			position: relative;
			padding: 0 30px;
		}

		p {
			text-align: left;
			font-size: 14px !important;
			line-height: 1.7;
		}


		.owners-label {
			border: 1px solid;
			padding: 10px;
			position: absolute;
			top: -70px;
			left: 50%;
			transform: translate(-50%, 0);
			background-color: #fff;
			width: 160px;
			margin: 0 auto;
			font-size: 12px;
			letter-spacing: 0.25em;
			text-align: center;
		}

		.owner-heading {
			margin-bottom: 15px;
			color: var(--color-primary);
			display: block;
			text-align: center;
			letter-spacing: 0.1em;
		}
	}
}