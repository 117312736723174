.unit-information-box-v1 {
	padding: 20px 15px;
	background-color: var(--color-grey-light);
	font-size: var(--font-size-md);
	border: 1px solid var(--color-grey-normal);

	.map {

		.sidebar-map {
			width: 100%;
			height: 300px;
			position: relative;
		}
	}

	.location {

		.fa {
			margin-right: 8px;
		}
	}

	.facts {

		li {
			margin-bottom: 5px;

			.fa {
				width: 30px;
			}

			.fa-stack {
				margin-left: -10px;
			}

		}
	}

	.stars {
		color: var(--color-stars);
	}

}